import { useEffect } from 'react';

export interface UseAutoFillArgs {
  onAutoFillStart?: (value: string) => void;
  onAutoFillCancel?: (value: string) => void;
}

export const useAutoFill = (
  ref: React.RefObject<HTMLInputElement>,
  args: UseAutoFillArgs
) => {
  useEffect(() => {
    ref.current?.addEventListener('animationstart', (e) => {
      switch (e.animationName) {
        case 'onAutoFillStart':
          return args.onAutoFillStart?.(ref.current?.value || '');

        case 'onAutoFillCancel':
          return args.onAutoFillCancel?.(ref.current?.value || '');
      }
    });
  }, [ref]);
};
