import clsx from 'clsx';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input, { InputProps } from '../Input';

/**
 * @deprecated Use password regex from the elements package instead.
 */
export const passwordRegex = new RegExp(
  /^(((?!.*[\s])(?=.*[A-Z])(?=.*[\W])(?=.*\d).{10,}))|((?!.*[\s])(.{20,}))$/
);

export interface PasswordInputProps
  extends Omit<InputProps, 'suffix' | 'type'> {}

/**
 * @deprecated Use PasswordInput from the elements package instead.
 */
const PasswordInput: FC<PasswordInputProps> = ({ label, onChange, ...p }) => {
  const { t: tCommon } = useTranslation('common');
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [passwordFocused, setPasswordFocused] = useState(false);

  function handleVisibilityToggleKeyPress(
    event: React.KeyboardEvent,
    visible: boolean
  ) {
    return [' ', 'Enter'].includes(event.key) && setPasswordHidden(visible);
  }

  const visibilityToggle = (
    <>
      {passwordHidden ? (
        <span
          tabIndex={0}
          role="button"
          aria-pressed="false"
          data-testid="password-hidden"
          className={clsx(
            'select-none',
            'material-icons-round',
            'cursor-pointer',
            'w-6',
            'focus:text-primary',
            passwordFocused
              ? 'text-primary'
              : 'text-darkMode-gray-light dark:text-darkMode-gray-medium'
          )}
          onClick={() => setPasswordHidden(false)}
          onKeyPress={(event) => handleVisibilityToggleKeyPress(event, false)}
        >
          visibility_off
        </span>
      ) : (
        <span
          tabIndex={0}
          role="button"
          aria-pressed="true"
          data-testid="password-visible"
          className={clsx(
            'select-none',
            'material-icons-round',
            'cursor-pointer',
            'w-6',
            'focus:text-primary',
            passwordFocused
              ? 'text-primary'
              : 'text-darkMode-gray-light dark:text-darkMode-gray-medium'
          )}
          onClick={() => setPasswordHidden(true)}
          onKeyPress={(event) => handleVisibilityToggleKeyPress(event, true)}
        >
          visibility
        </span>
      )}
    </>
  );

  return (
    <Input
      {...p}
      required
      onChange={onChange}
      suffix={visibilityToggle}
      label={label ?? tCommon('password')}
      onFocus={() => setPasswordFocused(true)}
      onBlur={() => setPasswordFocused(false)}
      type={passwordHidden ? 'password' : 'text'}
    />
  );
};

export default PasswordInput;
