import clsx from 'clsx';
import { HTMLMotionProps, motion } from 'framer-motion';
import { CSSProperties, ComponentPropsWithoutRef, FC, useRef } from 'react';
import { UseAutoFillArgs, useAutoFill } from './hooks';
import PasswordInput, {
  PasswordInputProps,
} from './specializations/PasswordInput';

/**
 *  @deprecated Use app/components/Form/components/Input/Input.tsx instead.
 */
// @ts-ignore
export interface InputProps
  extends Omit<ComponentPropsWithoutRef<'input'>, 'prefix'>,
    UseAutoFillArgs {
  label?: string;
  containerStyles?: CSSProperties;
  children?: (clsxStyles: string) => React.ReactElement;
  suffix?: JSX.Element | boolean;
  prefix?: JSX.Element | boolean;
  inputClassName?: string;
  containerMotionProps?: HTMLMotionProps<'div'>;
  status?: 'error' | boolean;
}

/**
 *  @deprecated Use app/components/Form/components/Input/Input.tsx instead.
 */
const Input: FC<InputProps> = ({
  label,
  id,
  disabled,
  children,
  containerStyles,
  suffix,
  onClick,
  className,
  inputClassName,
  containerMotionProps,
  status,
  prefix,
  readOnly,
  onAutoFillStart,
  onAutoFillCancel,
  ...inputProps
}) => {
  const isError = status === 'error';
  const ref = useRef<HTMLInputElement>(null);
  useAutoFill(ref, { onAutoFillStart, onAutoFillCancel });

  const inputContainerStyles = clsx(
    'Input-Deprecated-Container min-h-[64px] dark:bg-darkMode-gray-medium/[0.16] bg-darkMode-gray-light/10 flex items-center px-4 py-2 rounded-md shadow-sm',
    !readOnly && !isError && 'focus-within:bg-primary',
    !readOnly && !isError && 'focus-within:bg-opacity-[0.08]',
    isError && 'bg-darkMode-danger/10',
    disabled && 'opacity-40 grayscale',
    className
  );

  const inputStyles = clsx(
    'placeholder-darkMode-gray-medium/50 text-lg font-normal dark:placeholder-[#3B4044] block p-0 w-full text-darkMode-almostBlack dark:text-white placeholder-shown:text-lg bg-transparent border-0',
    !readOnly && !isError && 'focus:ring-0'
  );

  return (
    <motion.div
      onClick={onClick}
      style={containerStyles}
      className={inputContainerStyles}
      {...containerMotionProps}
    >
      {prefix}
      <label
        htmlFor={id}
        className={clsx(
          'block flex-1 text-sm font-medium',
          !readOnly && !isError && 'focus-within:text-primary',
          isError ? 'text-darkMode-danger' : 'text-darkMode-gray-medium/70 dark:text-darkMode-gray-medium'
        )}
      >
        {label}
        {children ? (
          children(inputStyles)
        ) : (
          <input
            id={id}
            ref={ref}
            {...inputProps}
            readOnly={readOnly}
            disabled={disabled}
            className={clsx(inputStyles, inputClassName)}
          />
        )}
      </label>
      {suffix}
    </motion.div>
  );
};

interface InputContainerProps extends InputProps, PasswordInputProps {
  specialization?: 'password';
}

/**
 *  @deprecated Use app/components/Form/components/Input/Input.tsx instead.
 */
const input = ({ specialization, ...p }: InputContainerProps) => {
  switch (specialization) {
    case 'password':
      return <PasswordInput {...p} />;
    default:
      return <Input {...p} />;
  }
};

export default input;
